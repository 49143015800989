import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>401 | You Are Not Authenticated !</h1>
    <p>Error: Port '4005' couldn't connect</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
